import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

interface Props {
    message: string;
    color: 'success' | 'warning' | 'error' ;
    open: boolean;
    handleClose: () => void;
}

export default function index({ message, color, open, handleClose }: Props) {
    
    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            handleClose();
        }
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <Alert onClose={handleClose}
                    severity={color}
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}