// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container  {
    box-shadow: none;
    border: 1px solid #E1E3EB;
    border-radius: 16px;
    width: 700px;
}

@media (max-width: 767px) {
    .card-container {
        width: 100%;
    }
}

.customn-select {
    width: 140px;
}

@media (max-width: 767px) {
    .customn-select {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/Reports/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".card-container  {\n    box-shadow: none;\n    border: 1px solid #E1E3EB;\n    border-radius: 16px;\n    width: 700px;\n}\n\n@media (max-width: 767px) {\n    .card-container {\n        width: 100%;\n    }\n}\n\n.customn-select {\n    width: 140px;\n}\n\n@media (max-width: 767px) {\n    .customn-select {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
