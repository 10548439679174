import { ChangeEvent, MouseEvent, ReactNode, useEffect, useState } from 'react'
import { Themes } from '../../Helpers/themes';
import Loader from '../../component/Loader';
import SnackPopup from '../../component/SnackPopup';
import loginImage from "../../Assests/loginImage.png";
import pichainlogo from "../../Assests/pichainlogo.png";
import { useAuthStore, useInformationStore } from "../../Helpers/store/loginStore";
import { getCustomer, userLogin } from "../../Helpers/Apiservice";
import { CustomerProfile } from "../../shared/TypeApiService";
import InputField from "../../component/InputField";
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button1 from "../../component/Button/index";
import { useNavigate } from 'react-router-dom';

const Signin = () => {

    const { name, Token, setName, setToken } = useAuthStore();
    const { setCustomerProfile } = useInformationStore();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const router = useNavigate();
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const { setAuthenticated } = useAuthStore();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarError, setSnackbarError] = useState<"error" | "success" | "warning">("success");
    const [showPassword, setShowPassword] = useState(false);
    const [validEmail, setValidEmail] = useState<string>("");
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDown = (event: any) => {
        event.preventDefault()
    }

    useEffect(() => {
        submitDisable();
    }, [])

    const handleEmail = (e: any) => {
        setUsername(e.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(e.target.value)) {
            setValidEmail("Enter valid email")
        } else {
            setValidEmail("")
        }
    }

    const handleLogin = () => {
   
        setLoading(true)
        let payload = {
            email: username,
            password: password
        }
        userLogin(payload)
            .then((res) => {
                let value = res.data
                if (value.code == 200) {
                    localStorage.setItem("AccessToken", JSON.stringify(value?.data));
                    setName(value.username);
                    setToken(value.data);
                    setAuthenticated(true);
                    localStorage.setItem("isAuth", JSON.stringify(true));
                    getCustomerDetails();
                    setLoading(false);
                    // handleSnackbarOpen()
                    // setSnackbarError("success");
                    // setSnackbarMessage("Logged in successfully");
                } else {
                    setLoading(false);
                    handleSnackbarOpen()
                    setSnackbarError("success");
                    setSnackbarMessage(value.data);
                }
            })
            .catch((err: any) => {
                setLoading(false);
                if (err?.response?.data) {
                    setSnackbarMessage(err?.response?.data?.data);
                    handleSnackbarOpen()
                    setSnackbarError("error");
                } else {
                    setSnackbarMessage("Internal Server Error");
                    setSnackbarError("error");
                    handleSnackbarOpen()
                }
            })
    }

    const loadScript = (src: string) => {
        return new Promise(function (resolve) {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = src
            script.async = true
            script.onerror = function () {
                resolve(false)
            }
            script.onload = function () {
                resolve(true)
            }
            document.body.appendChild(script)
        })
    }
    const getCustomerDetails = () => {
        const razorPayResponse: any = loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!razorPayResponse) {
            alert('You are offline...  Failed to load RazorPay SDK')
            return
        }
        getCustomer()
            .then((response) => {
                let res = response.data

                sessionStorage.setItem('test_orgId', res?.test_orgId);
                sessionStorage.setItem('live_orgId', res?.live_orgId);
                const customerProfile: CustomerProfile = {
                    username: res?.username,
                    email: res?.email,
                    contact_no: res?.contact_no,
                    role: res?.role,
                    apikey: res?.apikey,
                    procurement_mode: res?.procurement_mode,
                    org_name: res?.org_name,
                    auth_type: res?.auth_type,
                    lock_mechanism: res?.lock_mechanism,
                    locking_time_out: res?.locking_time_out,
                    password: res?.password,
                    digital: res?.digital,
                    orgId: res?.orgId,
                    test_orgId: res?.test_orgId,
                    live_orgId: res?.live_orgId,
                    login:true
                };
                setCustomerProfile(customerProfile);
                    router('/Dashboard');

            })
            .catch((err) => {
                console.log(err, "fghjkl");
            })

    }

    const submitDisable = () => {
        if (password != "" && validEmail === "") {
            return false
        } else {
            return true
        }
    }


    return (
        <Themes>
            <SnackPopup message={snackbarMessage}
                color={snackbarError}
                open={snackbarOpen}
                handleClose={handleSnackbarClose} />
            <Loader loading={loading} />
            <Grid container spacing={2}>
                {
                    isMobileScreen ? null
                        : <Grid item md={6} lg={6} xs={0} className="bg-light w-100">
                            <div className="d-flex flex-column justify-content-end">
                                <img src={loginImage} className="ImageLogin" alt="Big Image" style={{ alignSelf: "flex-end" }} />
                                <div className="containerContent">The best background check solution for businesses of all sizes</div>
                            </div>
                        </Grid>
                }

                <Grid item md={6} lg={6} xs={12} className="">
                    <Box className="d-flex justify-content-center p-4">
                        <div>
                            <div className="d-flex justify-content-center">
                                <img src={pichainlogo} alt="Description" width={127} height={38} />
                            </div>
                            <Box sx={{ marginTop: "25%" }} className="containerLogin">
                                <Typography className="fw-bold" sx={{ fontSize: "24px" }}>
                                    Sign in
                                </Typography>
                                <label className="LabelLogin">Email Address<span className="required">*</span></label>
                                <InputField placeholder="Enter Email Address" value={username} error={validEmail}
                                    onChange={(e: any) => handleEmail(e)} />

                                <label className="LabelLogin">Password<span className="required">*</span></label>
                                <InputField placeholder="Enter Password" type={showPassword ? 'text' : 'password'} onChange={(e: any) => setPassword(e.target.value)} value={password} className="w-100" endAdornment={
                                    <InputAdornment position='end' style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', marginRight: '10px' }}>
                                        <IconButton
                                            edge='end'
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDown}
                                            aria-label='toggle password visibility'
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                <Typography sx={{ fontSize: '14px', color: '#CE1212', cursor: 'pointer' }} onClick={() => router('/ForgotPassword')}>Forgot password?  </Typography>

                                {/* <Typography sx={{ fontSize: '14px', color: '#CE1212', cursor: 'pointer' }} >{snackbarMessage}</Typography> */}

                                <Button1 disabled={submitDisable()} className="w-100 mt-4" onClick={() => handleLogin()}>
                                    Sign In
                                </Button1>
                                <div className="d-flex justify-content-center mt-2">Don't have a account &nbsp; <b style={{ color: "#1E5198", cursor: 'pointer' }} onClick={() => router("/SignUp")}>Sign up</b></div>
                            </Box>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Themes>
    )
}

export default Signin
