import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 8,
      position: 'relative',
      backgroundColor: "white",
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 16,
      color:'grey',
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: "#AEAEAE",
        borderColor: "#AEAEAE",
        border:'2px solid grey'
      },
      '&:disabled': { // Added rule for disabled state
        backgroundColor: '#F7F8FA', // Example color for disabled background
        borderColor: '#EEEEEE', // Example color for disabled border
        color: 'black !important' // Example color for disabled text
      }
    },
  }));
  

const index = (props : any) => {
  let {
    inputLabel,
    defaultValue,
    placeholder,
    error
  } = props
    return (
        <div >
            <FormControl variant="standard" className='w-100'>
                <BootstrapInput  defaultValue={defaultValue} placeholder={placeholder} id="bootstrap-input" {...props}/>
                {error && <FormHelperText error>{error}</FormHelperText> }
            </FormControl>
        </div>
    )
}

export default index
