import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import './App.css';
import MainLayouyt from "./component/mainLayout/index";
import 'bootstrap/dist/js/bootstrap.js';
import { Themes } from './Helpers/themes';
import Signup from './app/Signup/index';
import Signin from './app/Signin/index';
import ForgotPassword from './app/ForgotPassword/index';
import Dashboard from './app/Dashboard/index';
import Services from './app/Services/index';
import Wallet from './app/Wallet/index';
import Settings from './app/Settings/index';
import Reports from './app/Reports/index';
import AccessDenied from './app/AccessDenied/index';
import "./styles/styles.css";
import { useAuthStore } from "./Helpers/store/loginStore";


function App() {
  const navigate = useNavigate();
  const [auth, setAuth] = useState<boolean>(false)
  useEffect(() => {
    const authStatus = !!localStorage.getItem('isAuth');
    setAuth(authStatus);
    
    if (!authStatus) {
      navigate('/');  // Redirect to signin if not authenticated
    }
  }, [setAuth]);
  // const authenticated = localStorage.getItem('isAuth');
  return (
    <Themes>
      <Routes>
        <Route path='/signup' element={<Signup />} />
        <Route path='/' element={<Signin />} />
        <Route path='/ForgotPassword' element={<ForgotPassword />} />
            <Route element={<MainLayouyt> <Outlet /> </MainLayouyt>}>
              <Route path='/Dashboard' element={<Dashboard />} />
              <Route path='/Services' element={<Services />} />
              <Route path='/Wallet' element={<Wallet />} />
              <Route path='/Settings' element={<Settings />} />
              <Route path='/Reports' element={<Reports />} />
            </Route>
      </Routes>
    </Themes>
  );
}

export default App;
