
import { ReactNode,useState } from 'react';
import Header from '../Header/header';
// import sideNav from '../sideNavigation/sideNav';
import { Box, Container } from '@mui/material';
import { Themes } from '../../Helpers/themes';
import { createContext ,useContext} from 'react';
import SideNav from '../sideNavigation/SideNav';


interface DashboardLayoutProps {
    children: ReactNode;
}



const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
    
    return (
        <Themes>
                <div className="mainLayout">
                    <SideNav />
                    <div className="LayoutContent"> 
                        <Header />
                        <main className="mainContent">
                            <Container maxWidth="xl" sx={{ backgroundColor: '#F7F8FA' }}>{children}</Container>
                        </main>
                     </div>
                </div>
        </Themes>
    );
};

export default DashboardLayout;
