import React from 'react';

const Index = (props: any) => {
    console.log(props, "hgfdfghui");

    return (
        <div className='mb-1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="8" fill={props.data} />
            </svg>
        </div>
    );
};

export default Index;