"use client";
import { SIGNIN_RESPONSE, SIGNIN_RQST_BODY } from "../../shared/TypeApiService";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthStore, useInformationStore } from "../store/loginStore";
import { BASE_URL,BULK_API_KEY } from "../config/config";

// const {environment } = useInformationStore();

// const {profile} = useInformationStore();

const BACKENDURL: string = BASE_URL;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 13000,
});

instance.interceptors.request.use((request) => {
  const tokenData = localStorage.getItem("AccessToken");
  const token = JSON.parse(`${tokenData}`);
  console.log(token);
  request.headers.Authorization = `Bearer ${token}`;
  request.headers.Apikey = BULK_API_KEY;
  // request.headers.Apikey = process.env.NEXT_PRIVATE_KEY;
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Login user
export const userLogin = async (payload: SIGNIN_RQST_BODY) => {
  const path = `${BACKENDURL}/api/v1/customer/sign-in`;
  const res = await instance.post(path, payload);
  return res;
};
export const getCustomer = async (): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/customer/get`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};
export const updatePassword = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/customer/update-password`;
  const res: AxiosResponse = await instance.post(path, payload);
  return res;
};

//Login user
export const getServices = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/organisation/get_avaliable_services?orgId=${payload}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};
export const getServices1 = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/serviceManager/getServiceAsList?orgId=${payload}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const getClientWallet = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/customer/getClientByorgId/?orgId=${payload}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const getWalletHistory = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/customer/statement`;
  const res: AxiosResponse = await instance.post(path, payload);
  return res.data;
};

export const disableServices = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/orgManager/removeService/?orgId=${payload.orgID}&serviceId=${payload.serviceId}`;
  const res: AxiosResponse = await instance.post(path);
  return res;
};
export const enableServices = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/orgManager/addOrgWithService`;
  const res: AxiosResponse = await instance.post(path, payload);
  return res;
};
export const verifyPaymentSignature = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/verify/payments/signature`;
  const res: AxiosResponse = await instance.post(path, payload);
  return res;
};

export const createPaymentOrder = async (orgID: any, payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/wallet/create_order/?orgId=${orgID}`;
  const res: AxiosResponse = await instance.post(path, payload);
  return res.data;
};
export const signUp = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/customer/sign-up`;
  const response: AxiosResponse = await axios.post(path, payload);
  return response;
};
export const forgotPassword = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/customer/forget-password`;
  const response: AxiosResponse = await axios.post(path, payload);
  return response;
};
export const ReportHistory = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/recent/?orgId=${payload}`;
  const response: AxiosResponse = await axios.get(path, payload);
  return response;
};

export const totalBreakDown = async (payload: any): Promise<AxiosResponse> => {
  // const path = `${BACKENDURL}/orgManager/removeService/?orgId=${payload.orgID}&serviceId=${payload.serviceId}`;
  const path = `${BACKENDURL}/transactions/getByOrg/usage/?startDate=${payload.startDate}&endDate=${payload.endDate}&type=${payload.type}&orgId=${payload.orgId}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const increasePercentage = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/date/?orgId=${payload.orgId}&startTime=${payload.startDate}&endTime=${payload.endDate}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const apiHitCounts = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/count/?orgId=${payload.orgId}&startTime=${payload.startDate}&endTime=${payload.endDate}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const predicatedDate = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/percentage/?orgId=${payload.orgId}&startTime=${payload.startDate}&endTime=${payload.endDate}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const reportDownload = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/?orgId=${payload.orgId}&day=20`;
  const headers = {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",};
  const res: AxiosResponse = await instance.get(path, { responseType: 'blob',headers });
 
  return res;
};

export const settingsProfile = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/api/v1/organisation/get/${payload}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const serviceUsageData = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/servicePredictions?orgId=${payload.orgId}&startDate=${payload.startDate}&endDate=${payload.endDate}&service_name=${payload.service_name}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const walletUsageData = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/walletPredictions?orgId=${payload.orgId}&startDate=${payload.startDate}&endDate=${payload.endDate}&service_name=${payload.service_name}`;
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const walletAllServices = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/allwalletPredictions/?orgId=${payload.orgId}&startDate=${payload.startDate}&endDate=${payload.endDate}`
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const serviceAllServices = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/transactions/getByOrg/allservicePredictions/?orgId=${payload.orgId}&startDate=${payload.startDate}&endDate=${payload.endDate}`
  const res: AxiosResponse = await instance.get(path);
  return res;
};

export const DocumentUpload = async (payload: any): Promise<AxiosResponse> => {
  const path = `${BACKENDURL}/v3/document_verification`;
  const response: AxiosResponse = await axios.post(path, payload);
  return response;
};

