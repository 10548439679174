// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonService {
    background-color: #DBFCDD !important;
    border-radius: 16px;
    color: #2E7D32;
    padding: 6px;
}
.ButtonService1 {
    background-color: #D8DBD9 !important;
    border-radius: 16px;
    color: #242424;
    padding: 6px;
}
.CancelButton {
    color: #1E5198 !important;
    border-color: #1E5198 !important;
}
.EnableButton {
    color: white !important;
    background-color: #1E5198 !important;
}
.EnableButton1 {
    color: white;
    background-color: #9A1E1E;
}`, "",{"version":3,"sources":["webpack://./src/app/Services/index.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,cAAc;IACd,YAAY;AAChB;AACA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,cAAc;IACd,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,gCAAgC;AACpC;AACA;IACI,uBAAuB;IACvB,oCAAoC;AACxC;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".ButtonService {\n    background-color: #DBFCDD !important;\n    border-radius: 16px;\n    color: #2E7D32;\n    padding: 6px;\n}\n.ButtonService1 {\n    background-color: #D8DBD9 !important;\n    border-radius: 16px;\n    color: #242424;\n    padding: 6px;\n}\n.CancelButton {\n    color: #1E5198 !important;\n    border-color: #1E5198 !important;\n}\n.EnableButton {\n    color: white !important;\n    background-color: #1E5198 !important;\n}\n.EnableButton1 {\n    color: white;\n    background-color: #9A1E1E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
