import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { alpha, styled } from '@mui/material/styles';


export const StyledButton = styled(Button)`
  border-radius: 8px;
  min-height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  text-transform: none;
`;

const KenButtonSecondary = styled(StyledButton)`
  border: 1px solid #b3bac5;
`;

const StatusButton = styled(Button)`
  border-radius: 4px;
  color: white;
  height: 20px;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 8px;
  box-shadow: none;
  text-transform: none;
`;

const RoundedButton = styled(Button)`
  border-radius: 32px;
  background: linear-gradient(114.33deg, #20365c 6.4%, #060b6b 108.85%);
  color: white;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 32px;
  box-shadow: none;
  text-transform: none;
  &&& {
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      background: #f3f5f7;
    }
  }
`;
const RoundedButtonOutlined = styled(Button)`
  border-radius: 32px;
  background: white;
  color: linear-gradient(114.33deg, #20365c 6.4%, #060b6b 108.85%);
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid linear-gradient(114.33deg, #20365c 6.4%, #060b6b 108.85%);
  padding: 9px 32px;
  box-shadow: none;
  text-transform: none;
  &&& {
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      background: #f3f5f7;
    }
  }
`;

export default function index(props : any) {
  const { variant, icon, label, children, buttonClass, disabled, style, type, form } = props;
  const propsCopy = Object.assign({}, props);
  if (icon) {
    propsCopy.startIcon = <img src={icon} alt="icon" />;
  }

  switch (variant) {
    case "secondary":
      return (
        <StyledButton
          data-testid="button"
          type={type}
          form={form}
          {...propsCopy}
          color='primary'
          variant="contained"
          disableElevation
          style={{
            backgroundColor: disabled
              ? "rgb(224 224 224)"
              : "#1E5198",
            color: disabled
              ? "white"
              : "white",
            ...style,
          }}
          className={buttonClass}
        >
          {label || children}
        </StyledButton>
      );
   

    default:
      return (
        <StyledButton
        data-testid="button"
        type={type}
        form={form}
        {...propsCopy}
        color='primary'
        variant="contained"
        disableElevation
        style={{
          backgroundColor: disabled
            ? "rgb(224 224 224)"
            : "#1E5198",
          color: disabled
            ? "white"
            : "white",
          ...style,
        }}
        // className='w-100'
        // className={buttonClass}
      >
        {label || children}
      </StyledButton>
      );
  }
}
