import { CustomerProfile } from "../../../src/shared/TypeApiService";
import { create } from "zustand";

// Define the store type
interface AuthStore {
  name: string;
  Token: string;
  amount: any;
  authenticated: boolean;
  setName: (name: string) => void;
  setToken: (Token: string) => void;
  setAmount: (amount: string) => void;
  setAuthenticated: (authenticated: boolean) => void;
}

export interface profileSlice {
  profile: CustomerProfile;
  setCustomerProfile: (profile: CustomerProfile) => void;
  environment: boolean;
  setEnvironment: (environment: boolean) => void;
}

// Create a store
export const useAuthStore = create<AuthStore>((set) => ({
  name: "",
  Token: "",
  amount: "0",
  authenticated: false,
  setName: (name) => set({ name }),
  setToken: (Token) => set({ Token }),
  setAmount: (amount) => set({ amount }),
  setAuthenticated: (authenticated) => set({ authenticated }),
}));

export const useInformationStore = create<profileSlice>((set) => ({
  profile: {
    username: "",
    email: "",
    contact_no: "",
    role: "",
    apikey: "",
    procurement_mode: "",
    org_name: "",
    auth_type: "",
    lock_mechanism: "",
    locking_time_out: 0,
    password: "",
    digital: "",
    orgId: "",
    test_orgId: "",
    live_orgId: "",
    login : false
  },
  setCustomerProfile: (profile: CustomerProfile) => set(() => ({ profile })),
  environment: true,
  setEnvironment: (environment: boolean) => {
    set({ environment });
  },
}));
