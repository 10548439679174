// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet-banner {
    position: relative;
    width: 100px;
    height: 100px;
    display: block;
}

@media (max-width: 767px) {
    .wallet-banner {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/Wallet/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".wallet-banner {\n    position: relative;\n    width: 100px;\n    height: 100px;\n    display: block;\n}\n\n@media (max-width: 767px) {\n    .wallet-banner {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
