import * as React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InputLabel, Typography } from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#F7F8FA',
    border: '1px solid #EEE',
    fontSize: 14,
    width: '100%',
    padding: '8px ',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&::placeholder': {
      color: '#aaa',
    },
  },
}));

const Placeholder = ( children  : any ) => {

  return <div style={{color :'#aaa'}}>{children}</div>;
};

export default function CustomizedSelects(props: any) {
  const { value, onChange, options, optionTopic, placeholder } = props;
  return (
    <div >
      <FormControl>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={value}
          displayEmpty
          onChange={onChange}
           sx={{width:'100%'}}
          input={<BootstrapInput placeholder={placeholder} />}
          renderValue={
            value !== "" ? undefined : () => <>{placeholder}</>
          }
          MenuProps={{
            classes: { paper: { maxHeight: 400 } },
            PaperProps: { style: { maxHeight: 400 } } // Set maxHeight here
          }}
          {...props}
        >
          <Typography className='px-3' color={"#1E5198"}>{optionTopic}</Typography>
          {options?.map((item: { value: string | number, label: string | number }, index: number) => (
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}