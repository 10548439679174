import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Modal, Typography, useMediaQuery } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import valletImg from '../../Assests/Wallet.svg';
import valletImg1 from '../../Assests/Wallet1.svg';
import "../../styles/styles.css";
// import "../../app/global.css";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardStore from "../../Helpers/store/mainLayoutStore";
import { useInformationStore } from '../../../src/Helpers/store/loginStore';
import { useAuthStore } from '../../Helpers/store/loginStore';
import { getClientWallet } from '../../Helpers/Apiservice';
// import { useRouter } from 'next/navigation';
import { useNavigate, useLocation } from 'react-router-dom';

import Loader from '../Loader';


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc !important' : '#51C555 !important',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35) !important' : 'rgba(0,0,0,.25) !important',
    boxSizing: 'border-box',
  },
}));

const container: React.CSSProperties = {
  border: '1px solid #E1E3EB',
  padding: '8px',
  borderRadius: '16px',
  backgroundColor: 'white'
}
const container1: React.CSSProperties = {
  border: '1px solid #9A1E1E',
  padding: '8px',
  borderRadius: '16px',
  backgroundColor: 'white',
  marginBottom: '16px'
}
const wrapper: React.CSSProperties = {
  fontSize: '20px',
  fontWeight: 700,
  padding: '4px',
  color: 'black'
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 584,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  padding: '32px',

};
const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 584,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  padding: '32px',
  border: 'none'

};


const Header: React.FC = () => {

   const location = useLocation();
  const pathname = location.pathname;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))
  const HeaderName = pathname.replace(/\//g, '');


  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const { bears, toggleBears } = DashboardStore();
  const { environment, setEnvironment } = useInformationStore();
  const { setAmount } = useAuthStore();
  const [data, setData] = useState<any>(0);
  const [walletBalance, setWalletBalance] = useState<string>("high");
  const [loading, setLoading] = useState<boolean>(false);
  const handleChecked = (e: any) => {
    setLoading(true);
    let val = e.target.checked;
    if (!val) {
      if (HeaderName == "Wallet") {
       router("/Dashboard")
      }
      setEnvironment(false)
      let payload = sessionStorage.getItem("test_orgId");
      getClientWallet(payload)
        .then((res : any) => {
          setLoading(false)
          let tempData = res.data;
          let response = tempData.amount.toFixed(2)
          if (response > 0) {
            setData(response)
            setEnvironment(false);
            if (response < 1000) {
              setWalletBalance("low")
            } else {
              setWalletBalance("high")
            }
            setAmount(response);
          } else {
            setData(response)
            hanldeModal();
            setWalletBalance("test")
          }
        })
        .catch((err : any) => {
          setLoading(false)
        })
    } else {
      let payload = sessionStorage.getItem("live_orgId");
      getClientWallet(payload)
        .then((res : any) => {
          setLoading(false)
          let tempData = res.data;
          let response = tempData.amount.toFixed(2)
          setData(response)
          setEnvironment(true);
          if (response < 1000) {
            setWalletBalance("low")
          } else {
            setWalletBalance("high")
          }
          setAmount(response);
        })
        .catch((err:any) => {
          setLoading(false)
        })
    }
  }
  const router = useNavigate();
  useEffect(() => {
    initiateData()
  }, [setData]);

  const initiateData = () => {
    let payload = environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId")

    getClientWallet(payload)
      .then((res : any) => {
        let tempData = res.data;
        let response = tempData.amount.toFixed(2)
        setData(response)
        if (response < 1000) {
          setWalletBalance("low")
        } else {
          setWalletBalance("high")

        }
        setAmount(response);
      })
      .catch((err:any) => {
        console.log(err, "dfghjk");
      })
  }

  const hanldeModal = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  }
  const handleClose1 = () => {
    setOpen1(false);
  }
  const handleWallet = () => {
    router('/Wallet');

  }

  return (

    <div>
      <Loader loading={loading} />
      <AppBar className='px-4 py-2 mb-2 mt-3' position="sticky" sx={{ backgroundColor: '#F7F8FA', 'box-shadow': 'none', border: 'none' }}>
        {
          walletBalance == "low" && <Box sx={container1} >
            <Typography sx={{ color: "#9A1E1E", fontWeight: 700 }} className='p-1'>Your wallet balance is very low. Please recharge immediately to avoid suspension of services.</Typography>
          </Box>
        }

        <Box sx={container}>
          <div className='d-flex justify-content-between'>
            <div className='d-flex gap-2'>
              {
                isMobileScreen ? <MenuIcon sx={{ color: 'black' }} className="mt-2" onClick={toggleBears} /> : <Typography sx={wrapper}>{HeaderName}</Typography>
              }

            </div>
            <div className='d-flex gap-4'>
              <Button className='btn-liveMode d-flex gap-2' ><Typography className='fw-bold'>Live Mode</Typography><AntSwitch checked={environment} onChange={(e) => handleChecked(e)} inputProps={{ 'aria-label': 'ant design' }} /></Button>
              <Button className={walletBalance === "low" ? 'btn-Waller1 d-flex gap-2' : 'btn-Waller d-flex gap-2'} onClick={() => handleWallet()}> <img src={walletBalance === "low" ? valletImg1 : valletImg} alt="Description" /> <div className='fw-bold'>₹{data}</div></Button>
            </div>
          </div>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography className='fw-bold' style={{ fontSize: '20px' }}>Want credits for Test mode?</Typography>
            <Typography className='mt-2'>Please reach out to PiChain Support at <b style={{ color: "#1E5198" }}>contactus@pichainlabs.com</b></Typography>
            <div className='d-flex justify-content-center gap-3 mt-4'>
              <Button variant='contained' className='EnableButton' onClick={handleClose}>Close</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1}>
            <Typography className='fw-bold'>Your services have been temporarily suspended due to insufficient balance.</Typography>
            <Typography className='mt-2'>Please recharge your wallet now to resume services.</Typography>
            <div className='d-flex justify-content-center gap-3 mt-4'>
              <Button variant='outlined' className='CancelButton' onClick={handleClose1}>Sign Out</Button>
              <Button variant='contained' className='EnableButton' onClick={handleClose}>Recharge Wallet</Button>
            </div>
          </Box>
        </Modal>
      </AppBar>
    </div>

  );
};

export default Header;