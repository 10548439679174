// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panelYes {
    text-decoration: underline;
    color: #1e5198 !important;
    font-weight: 700;
    cursor: pointer;
    margin-top: 3px;
  
  }
  .panelNo {
    cursor: pointer;
    margin-top: 3px;
  }
  
  .BreakdownDetails {
    border-radius: 8px;
    border: 1px solid #e1e3eb;
    padding: 24px;
    height: 270px;
    overflow-y: scroll;
    
  }
  .breakContainer {
    height: 300px;
    overflow-y: scroll;
  }
  .BreakdownDetails::-webkit-scrollbar {
    width: 4px;
  }
  
  .BreakdownDetails::-webkit-scrollbar-thumb {
    background-color: rgb(255 255 255);
  }
  
  .BreakdownDetails::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .amountBox {
    border-radius: 8px;
    border: 1px solid #eee;
    background: #f7f8fa;
    width: fit-content;
    padding: 4px;
    min-width: 30px;
    display: flex;
    justify-content: center;
  }
  .InsightCard {
    border-radius: 16px;
    border: 1px solid #e1e3eb;
    padding: 16px;
    background: #fefefe;
    height: 136px;
  }
  .InsightHead {
      font-weight: 600;
  }
  
  .chartCanvas {
    background: linear-gradient(180deg, #E1EBFF 0%, rgba(247, 248, 250, 0.00) 100%);
    stroke-width: 1px;
    stroke: #1E5198;
  }`, "",{"version":3,"sources":["webpack://./src/app/Dashboard/index.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,eAAe;;EAEjB;EACA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,kBAAkB;;EAEpB;EACA;IACE,aAAa;IACb,kBAAkB;EACpB;EACA;IACE,UAAU;EACZ;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,uBAAuB;EACzB;EACA;IACE,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,aAAa;EACf;EACA;MACI,gBAAgB;EACpB;;EAEA;IACE,+EAA+E;IAC/E,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".panelYes {\n    text-decoration: underline;\n    color: #1e5198 !important;\n    font-weight: 700;\n    cursor: pointer;\n    margin-top: 3px;\n  \n  }\n  .panelNo {\n    cursor: pointer;\n    margin-top: 3px;\n  }\n  \n  .BreakdownDetails {\n    border-radius: 8px;\n    border: 1px solid #e1e3eb;\n    padding: 24px;\n    height: 270px;\n    overflow-y: scroll;\n    \n  }\n  .breakContainer {\n    height: 300px;\n    overflow-y: scroll;\n  }\n  .BreakdownDetails::-webkit-scrollbar {\n    width: 4px;\n  }\n  \n  .BreakdownDetails::-webkit-scrollbar-thumb {\n    background-color: rgb(255 255 255);\n  }\n  \n  .BreakdownDetails::-webkit-scrollbar-track {\n    background-color: #f1f1f1;\n  }\n  .amountBox {\n    border-radius: 8px;\n    border: 1px solid #eee;\n    background: #f7f8fa;\n    width: fit-content;\n    padding: 4px;\n    min-width: 30px;\n    display: flex;\n    justify-content: center;\n  }\n  .InsightCard {\n    border-radius: 16px;\n    border: 1px solid #e1e3eb;\n    padding: 16px;\n    background: #fefefe;\n    height: 136px;\n  }\n  .InsightHead {\n      font-weight: 600;\n  }\n  \n  .chartCanvas {\n    background: linear-gradient(180deg, #E1EBFF 0%, rgba(247, 248, 250, 0.00) 100%);\n    stroke-width: 1px;\n    stroke: #1E5198;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
