import * as React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import InputField from "../InputField/index"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import "./index.css"

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    ' border- radius': "8px",
    border: '1px solid var(--Neutral-light-gray-100, #E1E3EB)',
    background: "#F7F8FA",
    fontSize: 14,
    width: '100%',
    padding: '8px',
    // height:
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: "#AEAEAE",
      borderColor: "#AEAEAE",
    },
    '&:disabled': { // Added rule for disabled state
      backgroundColor: '#F7F8FA', // Example color for disabled background
      borderColor: '#EEEEEE', // Example color for disabled border
      color: 'black !important' // Example color for disabled text
    }
  },
}));

export default function BasicDatePicker(props: any) {

  return (
    <div >
      <BootstrapInput
        defaultValue="edwed"
        placeholder="efdefde"
        id="bootstrap-input"
        type="date"
        value={props.value}
        onChange={props.handleChange} className="w-100"// Attach the onChange handler
        {...props}
      />
    </div>

  );
} 
