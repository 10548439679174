import { create } from "zustand";

interface BearState {
    bears: boolean;
    toggleBears: () => void;
  }

  const DashboardStore = create<BearState>((set) => ({
    bears: false,
    toggleBears: () => set((state) => ({ bears: !state.bears })),
}));

export default DashboardStore;