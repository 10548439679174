import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import loginImage from "../../Assests/loginImage.png";
import pichainlogo from "../../Assests/pichainlogo.png";
import InputField from "../../component/InputField";
import { forgotPassword } from "../../../src/Helpers/Apiservice";
import Loader from "../../../src/component/Loader";
import SnackPopup from '../../component/SnackPopup';



const Page = () => {
    const [email, setEmail] = useState("kavinkisvinth@gmail.com");
    const [validEmail, setValidEmail] = useState("");
    const [valid, setValid] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [mailsent, setMailSent] = useState<boolean>(false)
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarError, setSnackbarError] = useState<"error" | "success" | "warning">("success");

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleEmail = (e: any) => {
        setEmail(e.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(e.target.value)) {
            setValidEmail("Enter valid email");
            setValid(true);
        } else {
            setValidEmail("");
            setValid(false);
        }
    }

    const handleLogin = () => {
        setLoading(true)
        let payload = {
            email: email
        }
        forgotPassword(payload)
            .then((res) => {
                setLoading(false);
                setMailSent(true);
                // handleSnackbarOpen();
            }).catch((err) => {
                setLoading(false);
                handleSnackbarOpen()
                setSnackbarError("error");
                setSnackbarMessage(err?.response?.data?.data);
                setMailSent(false);

            })
    }

    return (
        <div>
            <Loader loading={loading} />
            <SnackPopup message={snackbarMessage}
                color={snackbarError}
                open={snackbarOpen}
                handleClose={handleSnackbarClose} />
            <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={0} className="h-100 bg-light w-100 ">
                    <div className="d-flex flex-column justify-content-end">
                        <img src={loginImage} className="ImageLogin" alt="Big Image" style={{ alignSelf: "flex-end" }} />
                        <div className="containerContent">The best background check solution for businesses of all sizes</div>
                    </div>
                </Grid>
                <Grid item md={6} lg={6} xs={12} className="h-100 ">
                    <Box className="d-flex justify-content-center p-4">
                        <div>
                            <div className="d-flex justify-content-center">
                                <img src={pichainlogo} alt="Description" width={127} height={38} />
                            </div>
                            {
                                    !mailsent ?
                                    <Box sx={{ marginTop: "25%" }} className="containerLogin">
                                        <Typography className="fw-bold" sx={{ fontSize: "24px" }}>
                                            Forgot Password
                                        </Typography>
                                        <label className="LabelLogin">Email Address<span className="required">*</span></label>
                                        <InputField placeholder="Enter Email Address" error={validEmail} onChange={(e: any) => handleEmail(e)} /> <br />
                                        <Button disabled={valid} className="w-100 mt-4  EnableButton" variant="contained" onClick={() => handleLogin()}>
                                            Submit
                                        </Button>
                                    </Box>
                                    :
                                    <Box sx={{ marginTop: "25%" }} className="containerLogin">
                                        <Typography className="fw-bold" sx={{ fontSize: "24px" }}>
                                            Forgot Password
                                        </Typography>
                                        <Typography className="mt-4">An email has been sent to <span className="fw-bold" >{email}</span></Typography>
                                        <Typography >with a new password. Please check your email.</Typography>

                                    </Box>
                            }


                        </div>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default Page;
