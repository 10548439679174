"use client"
import { Box, Button, Card, Grid, Paper, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import rupee from '../../Assests/rupee.svg';
// import "./index.css";
import { styled } from '@mui/material/styles';
import { getServices } from '../../Helpers/Apiservice'
import Loader from '../../component/Loader';
import { useInformationStore } from '../../Helpers/store/loginStore';
import EnableService from '../../component/EnableService';
import SnackPopup from '../../component/SnackPopup';
import { Iservice } from '../../shared/services';
import isAuth from '../../Helpers/isAuth';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./index.css";


const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#2E7D32',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#2E7D32'
  },
}));




const Page = () => {
  const [data, setData] = useState<Iservice[]>([])
  const [open, setOpen] = React.useState(false);
  const [modalType, setModalType] = useState("");
  const [refresh, setRefresh] = useState<number>(0)
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState<Iservice | undefined>();
  const label = { inputProps: { 'aria-label': 'controlled' } };
  const { environment } = useInformationStore();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [error, setError] = useState<'success' | 'warning' | 'error'>("error");

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    setLoading(true);
    let payload = environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId");

    getServices(payload)
      .then(res => {
        setLoading(false);
        let temp_Array = res.data.data;
        console.log(temp_Array);        

        if (payload === "63035b3050e65c8eeef4ac19" || payload === "660be65e4b32c394790fb13a") {
          let surepass = temp_Array.filter((item: Iservice) => item.type[0] === "Aadhaar").sort((a: Iservice, b: Iservice) => a.type[0].localeCompare(b.type[0]));
          setData(surepass);
        } else {
          let sortedArray = temp_Array.reverse().sort((a: Iservice, b: Iservice) => a.type[0].localeCompare(b.type[0]));
          setData(sortedArray);
        }

        if (temp_Array.length < 1) {
          setSnackbarOpen(true);
          setSnackbarMessage("No services");
          setError("error");
        }
      })
      .catch(err => {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Something went wrong");
        setError("error");
        console.log(err);
      });
  }, [environment, refresh]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, item: Iservice) => {
    handleOpen();
    if (item.subscribed) {
      setModalType("disable");
      setModalData(item)
    } else {
      setModalType("enable");
      setModalData(item)
    }
  };



  return (
    <div>
      <Loader loading={loading} />
      <SnackPopup message={snackbarMessage}
        color={error}
        open={snackbarOpen}
        handleClose={handleSnackbarClose} />
      {
        data.length > 0 ?
        <Grid container spacing={6}>
  {data.map((item: Iservice, index: number) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
      <Card elevation={0} className='p-4' style={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <Typography sx={{ fontSize: '18px' }} className='fw-bold'>{item.serviceName}</Typography>
          <p>{item.description}</p>
        </div>
        <div>
          <div className='d-flex align-items-center gap-2'>
            <img src={rupee} alt='rupee' />
            <Typography style={{ fontSize: "48px" }} className='fw-bold'>{item.defaultRate}</Typography>
            <Typography className='mt-3'>per request</Typography>
          </div>
          <div className='d-flex gap-1 align-items-end mt-2'>
            {item.subscribed ? <div className='ButtonService'>Enabled</div> : <div className='ButtonService1'>Disabled</div>}
            <PinkSwitch {...label} checked={item.subscribed} onChange={(event) => handleSwitchChange(event, index, item)} />
          </div>
        </div>
      </Card>
    </Grid>
  ))}
</Grid> :
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card elevation={0} className='p-4'>
                <Stack spacing={1}>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <br />
                  <Skeleton variant="rounded" width={210} height={60} />
                  <br />
                  <div className='d-flex justify-content-start align-items-center gap-3'>
                    <Skeleton variant="rectangular" width={100} height={30} />
                    <Skeleton variant="rectangular" width={30} height={15} />
                  </div>
                </Stack>
              </Card>
            </Grid>
          </Grid>
      }

      {
        open ? <EnableService open={open} setOpen={setOpen} type={modalType} data={modalData} setRefresh={setRefresh} refresh={refresh} /> : null
      }
    </div>
  )
}

export default Page;
