import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { disableServices, enableServices } from '../../Helpers/Apiservice';
import { useInformationStore } from '../../Helpers/store/loginStore';
import { Iservice, serviceData } from '../../shared/services';
import Loader from '../Loader';
import SnackPopup from '../SnackPopup';
import Button1 from "../Button/index";



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 584,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    padding: '32px'
};

const Index = ({ open, setOpen, type, data, setRefresh,refresh }: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, type: string, data: Iservice | undefined, setRefresh: any ,refresh : number}) => {
    const { environment, setEnvironment } = useInformationStore();
    const [loading, setLoading] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [error, setError] = useState<'success' | 'warning' | 'error'>("success");

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };



    const handleEnable = (e: any) => {
        setLoading(true)

        let payload = {
            orgID: environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId"),
            clientID: "",
            serviceId: data?.id,
            rate: data?.defaultRate,
            statusCode: data?.statusCode
        }
        enableServices(payload)
            .then((res) => {
                if (res) {
                    setLoading(false);
                    setError("success");
                    setSnackbarOpen(true);
                    setSnackbarMessage("Enable Successfully");
                    setRefresh(refresh + 1);
                    setOpen(false);
                }

            })
            .catch((err) => {
                setLoading(false);
                setError("error");
                setSnackbarOpen(true);
                setSnackbarMessage("Something went wrong");
            })
    }

    const handleDisable = () => {
        setLoading(true)

        let payload = {
            orgID: environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId"),
            serviceId: data?.id,
        }
        disableServices(payload)
            .then((res) => {
                if (res) {
                    setLoading(false);
                    setRefresh(refresh + 1)
                    setOpen(false);
                    setError("success");
                    setSnackbarOpen(true);
                    setSnackbarMessage("Disable Successfully");
                }
            })
            .catch((err) => {
                setLoading(false);
                setError("error");
                setSnackbarOpen(true);
                setSnackbarMessage("Something went wrong");
            })
    }

    const handleClose = () => {
        setOpen(!open)
    }

    return (
        <div>
            <Loader loading={loading} />
            <SnackPopup message={snackbarMessage}
                color={error}
                open={snackbarOpen}
                handleClose={handleSnackbarClose} />
            {
                type == "enable" ?
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography>Enable <b>{data?.serviceName} </b>?</Typography> <br />
                            <Typography>By enabling this service you will be charged <b>₹{data?.defaultRate}</b> for each request from your wallet.</Typography> <br />
                            <Typography>Please note that the service will be paused automatically when
                                the wallet balance is insufficient.</Typography>
                            <div className='d-flex justify-content-center gap-3 mt-4'>
                                <Button variant='outlined' className='CancelButton' onClick={handleClose}>Cancel</Button>
                                <Button1  onClick={(e:any) => handleEnable(e)}>Enable</Button1>
                            </div>
                        </Box>
                    </Modal> : <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography>Disable <b>{data?.serviceName}</b>?</Typography>
                            <Typography className='mt-2'>You can re-enable this service once again at any time. </Typography>  <br /> <br /> <br />
                            <div className='d-flex justify-content-center gap-3 mt-4'>
                                <Button variant='outlined' className='CancelButton' onClick={handleClose}>Go Back</Button>
                                <Button variant='contained' className='EnableButton1' onClick={handleDisable}>Disable</Button>
                            </div>
                        </Box>
                    </Modal>
            }

        </div>
    )
}

export default Index
