"use client";
import "bootstrap/dist/css/bootstrap.css";
// import "../global.css";
import React, { useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import { Button, Card, Grid, Typography, IconButton } from "@mui/material";
import InputField from "../../component/InputField";
import { updatePassword, settingsProfile } from "../../Helpers/Apiservice";
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Loader from "../../component/Loader";
import SnackPopup from "../../component/SnackPopup";
import { useInformationStore } from "../../Helpers/store/loginStore";
import Button1 from "../../component/Button/index";
import * as XLSX from 'xlsx';
import { IsettingsData } from "../../shared/settings";
import "./index.css"

const container: React.CSSProperties = {
    boxShadow: "none",
    border: "1px solid #E1E3EB",
    borderRadius: "16px",
};


interface State {
    oldPassword: string,
    new_password: string,
    confrim_password: string
}


const Page = () => {
    const [type, setType] = useState<string>("Account");
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [confrimError, setConfrimError] = useState("");
    const [errorPassword, setErrorPassword] = useState<string>("");
    const [confrimStatus, setConfrimStatus] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [profileData, setProfileData] = useState<IsettingsData>();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarError, setSnackbarError] = useState<"error" | "success" | "warning">("success");
    const [values, setValues] = useState({
        oldPassword: "",
        new_password: '',
        confrim_password: ""
    });
    const { profile, environment } = useInformationStore()

    console.log(profile, "fejwjbfiej");

    useEffect(() => {
        setLoading(true);

        let payload = environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId")

        settingsProfile(payload)
            .then((res) => {
                setLoading(false);
                let response = res.data.data;
                console.log(response, "responseuwfwufwufn");
                setProfileData(response)
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })
    }, [environment])


    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const handleClickShowPassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1)
    }

    const handleMouseDownPassword1 = (event:  MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleSubmit = () => {
        setLoading(true);
        let payload = {
            password: values.oldPassword,
            new_password: values.new_password
        }
        updatePassword(payload)
            .then((res) => {
                console.log(res);
                setLoading(false);
                handleSnackbarOpen();
                setSnackbarMessage(res?.data?.status?.statusMessage);
                setSnackbarError("success");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                if (err?.response?.data) {
                    handleSnackbarOpen()
                    setSnackbarError("error");
                    setSnackbarMessage(err?.response?.data?.status?.statusMessage);
                } else {
                    handleSnackbarOpen()
                    setSnackbarError("error");
                    setSnackbarMessage("Internal Server Error");
                }
            })

    }

    const handleDownload = () => {
        const worksheet = XLSX.utils.json_to_sheet([{ orgId: profileData?.orgId, Apikey: profileData?.apikey }]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'test_credentials.xlsx');
    };


    const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, "confrim_password": event.target.value });
        let value = event.target.value;
        if (value == values.new_password) {
            setConfrimError("");
            setConfrimStatus(false);
        } else {
            setConfrimError("Passwords do not match. Please try again.");
            setConfrimStatus(true);
        }
    }
    const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
        if (prop === 'new_password') {
            const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
            if (!passwordRegex.test(event.target.value)) {
                setErrorPassword("Password needs 8+ characters with digits, special characters, letters.");
                setValues({ ...values, [prop]: event.target.value });
            } else {
                setErrorPassword("");
                setValues({ ...values, [prop]: event.target.value });
            }
            if (event.target.value == values.new_password) {
                setConfrimError("");
                setConfrimStatus(false);
            } else {
                setConfrimError("Passwords do not match. Please try again.");
                setConfrimStatus(true);
            }
        } else {
            setValues({ ...values, [prop]: event.target.value });

        }
    }

    const submitEnable = () => {
        if (values.confrim_password != "" && values.new_password != "" && values.oldPassword != "" && !confrimStatus && errorPassword == "") {
            return false;
        } else {
            return true
        }
    }

    const handleReset = () => {
        setValues({
            oldPassword: "",
            new_password: "",
            confrim_password: ""
        })


    }


    return (
        <div className="p-3">
            <Loader loading={loading} />
            <SnackPopup message={snackbarMessage}
                color={snackbarError}
                open={snackbarOpen}
                handleClose={handleSnackbarClose} />

            <div className="d-flex gap-3">
                <Typography className={type === "Account" ? "panelYes" : "panelNo"} onClick={() => setType("Account")}>
                    Account
                </Typography>

                <Typography className={type === "Password" ? "panelYes" : "panelNo"} onClick={() => setType("Password")}>
                    Password
                </Typography>
            </div>
            {
                type === "Account" ?
                    <>
                        <Card sx={container} className="p-4 mt-4">
                            <Typography className="fw-bold">{profileData?.org_name}</Typography>
                            <Typography className="mt-3">Username: {profileData?.org_name}</Typography>
                            <Typography >Email: {profileData?.email}</Typography>
                        </Card><br />
                        <div className="mt-4">
                            <Typography className="fw-bold">API Credentials</Typography>
                        </div>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item md={8}  xs={12}>
                                    <Card sx={container} className="p-4 mt-4">
                                        <Typography className="fw-bold mb-2">Test Credentials</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={5} sm={6}>
                                                <label className="LabelLogin">Organisation ID</label>
                                                <InputField placeholder="Enter the organization ID" value={profileData?.orgId} readonly />
                                            </Grid>
                                            <Grid item xs={12} md={5} sm={6}>
                                                <label className="LabelLogin">Test API Key</label>
                                                <InputField placeholder="Enter the Test API Key" value={profileData?.apikey} readonly />
                                            </Grid>
                                            <Grid item xs={12} md={2} sm={6} className="">
                                                <br />
                                                <Button1 className=" mt-4 "  onClick={() => handleDownload()}>
                                                    Download
                                                </Button1>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                {/* <Grid item md={4} xs={12}>
                                    <Card sx={container} className="p-4 mt-4">
                                        <Typography className="fw-bold mb-2">Postman API Documentation</Typography>
                                        <Button className=" mt-1 CancelButton" variant='outlined' >
                                            Download Collection
                                        </Button>
                                    </Card>
                                </Grid> */}
                            </Grid>

                        </div>
                    </> : <>
                        <Card sx={container} className="p-4 mt-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="password-container">
                                    <Typography className="fw-bold">Change Password</Typography>
                                    <label className="LabelLogin">Old Password<span className="required">*</span></label>

                                    <InputField value={values.oldPassword} placeholder="Enter the current password" type={showPassword2 ? 'text' : 'password'} onChange={handleChange('oldPassword')} endAdornment={
                                        <InputAdornment position='end' style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', marginRight: '10px' }}>
                                            <IconButton
                                                edge='end'
                                                onClick={handleClickShowPassword2}
                                                onMouseDown={handleMouseDownPassword1}
                                                aria-label='toggle password visibility'
                                            >
                                                {showPassword2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    } />
                                    <label className="LabelLogin">New Password<span className="required">*</span></label>


                                    <InputField value={values.new_password} placeholder="Enter the new password" type={showPassword ? 'text' : 'password'} onChange={handleChange('new_password')} error={errorPassword} endAdornment={
                                        <InputAdornment position='end' style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', marginRight: '10px' }}>
                                            <IconButton
                                                edge='end'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword1}
                                                aria-label='toggle password visibility'
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    } />
                                    <label className="LabelLogin">Confirm Password<span className="required">*</span></label>

                                    <InputField error={confrimError}
                                        placeholder="Confirm the new password"
                                        type={showPassword1 ? 'text' : 'password'}
                                        value={values.confrim_password}
                                        onChange={handleChange1}
                                        endAdornment={
                                            <InputAdornment position='end' style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', marginRight: '10px' }}>
                                                <IconButton
                                                    edge='end'
                                                    onClick={handleClickShowPassword1}
                                                    onMouseDown={handleMouseDownPassword1}
                                                    aria-label='toggle password visibility'
                                                >
                                                    {showPassword1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <Grid container spacing={2} className="mt-3">
                                        <Grid item xs={4}>
                                            <Button className="w-100 mt-1 CancelButton" variant='outlined' onClick={() => handleReset()}>
                                                Reset
                                            </Button>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button1 className="w-100 mt-1"  onClick={() => handleSubmit()} disabled={submitEnable()}>
                                                Save Changes
                                            </Button1>
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>
                        </Card></>
            }

        </div>
    )
}

export default Page;
