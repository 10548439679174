// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-container {
    width: 40%;
}

@media (max-width: 767px) {
    .password-container {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/Settings/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".password-container {\n    width: 40%;\n}\n\n@media (max-width: 767px) {\n    .password-container {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
