"use client";
import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from 'react';
import { Button, Card, Grid, Paper, Typography } from "@mui/material";
import CustomizedSelects from "../../component/DropDown";
import DatePicker from "../../component/DatePicker";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ReportHistory, reportDownload } from "../../Helpers/Apiservice";
import { useInformationStore } from "../../Helpers/store/loginStore";
import Loader from "../../component/Loader";
import Button1 from "../../component/Button/index";
import { IHistoryUnique, IRechargeOptions, ITransactionData } from "../../shared/Reports";
import "./index.css"


const Page = () => {
    const [recharge, setRecharge] = useState("");
    const { environment } = useInformationStore();
    const [data, setData] = useState<ITransactionData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = React.useState<string | null>('');
    const [downloadButton, setDownloadButton] = React.useState<boolean>(true)
    const [downloadButton1, setDownloadButton1] = React.useState<boolean>(true)
    const [rechargeOptions, setRechargeOptions] = useState<IRechargeOptions[]>([]);
    const [filterData, setFilterData] = useState<ITransactionData[] | []>([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.value != "") {
            setDownloadButton(false)
        } else {
            setDownloadButton(true)
        }
        setValue(event.target.value);
    }
    const defaultDate = new Date(); // For example, today's date
    const [value1, setValue1] = useState<string | null>("");

    const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {

        setValue1(event.target.value);

        if (event.target.value != "") {
            setDownloadButton1(false)
        } else {
            setDownloadButton1(true)
        }
    }
    const handleRecharge = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRecharge(event.target.value as string);
        console.log(event.target.value, "efbefefuefcui");
        const filteredData = data.filter((item: ITransactionData) => item.serviceName === event.target.value);
        setFilterData(filteredData)

    };
    // 


    const convertToFormat = (date1: Date) => {
        const date = new Date(date1);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    useEffect(() => {
        setLoading(true)
        let payload = environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId")
        ReportHistory(payload)
            .then((res) => {
                setLoading(false);
                let response: ITransactionData[] = res.data;
                // Map and compress the data
                let compress : IHistoryUnique[]  = response.map(item => ({
                    Time: new Date(item.time.slice(0, 10)),
                    services: item.serviceName
                }));
                let uniqueServices = compress.reduce((unique: any, item) => {
                    return unique.includes(item.services) ? unique : [...unique, item.services];
                }, []);

                let rechargeOptions = uniqueServices.map((service: IRechargeOptions) => ({
                    label: service,
                    value: service
                }));

                setRechargeOptions(rechargeOptions)
                rechargeOptions.push({ label: "All services", value: "All_services" });

                compress.sort((a: IHistoryUnique, b: IHistoryUnique) => a.Time - b.Time);

                let leastDate = compress.length > 0 ? compress[0].Time : null;
                let previousDate = compress.length > 1 ? compress[1].Time : null;

                setValue(leastDate == null ? "" :convertToFormat(leastDate))
                setValue1(leastDate == null ? "" :convertToFormat(previousDate))
                setData(response);
                console.log(response,"forInterface");
                
                setFilterData(response);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
    }, [environment]);

    function convertTimestamp(timestamp: string): string {
        const date = new Date(timestamp);

        // Get components of the date
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear().toString().substr(-2);


        // Format the date and time
        const formattedDate = `${hours}:${minutes}:${seconds}, ${day} ${month} ${year}`;

        return formattedDate;
    }
    const column: GridColDef[] = [
        {
            field: "serviceName",
            headerName: "API Name",
            // width: 250,
            flex: 1,

            renderCell: (params) => <div>{params.value}</div>,
        },

        {
            field: "chargedAmount",
            headerName: "API Cost",
            // width: 250,
            flex: 1,
            renderCell: (params) => (
                <div> ₹{params.value}</div>
            )
        },
        {
            field: "statusCode",
            headerName: "Status Code",
            flex: 1,
            // width: 250
        },
        {
            field: "time",
            headerName: "Timestamp ",
            width: 250,
            renderCell: (params) => <div>{convertTimestamp(params.value)}</div>
        },
        {
            field: "transactionID",
            headerName: "Reference ID",
            flex: 1,
            // width: 250
        }
    ];

    const downloadReport = () => {
        setLoading(true);
    
        let payload = {
            orgId: environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId")
        };
    
        reportDownload(payload)
            .then((res) => {
                setLoading(false);
                if (res && res.data) {
                    console.log(res.data,"kjhgfdsdftyuio");
                    
                    // Create a Blob from the received data
                    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
                    // Create a URL for the Blob
                    const url = window.URL.createObjectURL(blob);
    
                    // Create a link element
                    const link = document.createElement('a');
                    link.href = url;
    
                    // Set the filename for the downloaded file
                    link.setAttribute('download', 'report.xlsx');
    
                    // Append the link to the body and trigger the click event
                    document.body.appendChild(link);
                    link.click();
    
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(link);
                } else {
                    console.error('Empty response or missing data in response.');
                    // Handle empty response or missing data
                }
    
            })
            .catch((err) => {
                setLoading(false);
                console.error(err, "err");
                // Handle error
            });
    };
    
    return (
        <div>
            <Loader loading={loading} />
            <Card  className="p-3 card-container">
                <Grid container spacing={2} display={"flex"} alignItems={"center"}>

                    <Grid item md={3} xs={6}>
                        <label className="fw-bold">From</label>
                        <DatePicker value={value} handleChange={handleChange} minDate={value}
                            maxDate={value1} />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <label className="fw-bold">To</label>
                        <DatePicker value={value1} handleChange={handleChange1} />
                    </Grid>
                    <Grid item md={3}  xs={6} className="mt-4">
                        <CustomizedSelects value={recharge} onChange={handleRecharge} options={rechargeOptions} className="customn-select"  placeholder="All Services" />
                    </Grid>
                    <Grid item md={3}  xs={6} className="mt-4">
                        {
                            filterData.length > 0 ? <Button1 className='w-100' onClick={() => downloadReport()} >Download</Button1> : 
                        <Button1 className='w-100' onClick={() => downloadReport()} disabled={true}>Download</Button1>

                        }
                    </Grid>
                </Grid>
            </Card>
            <div className="mt-4">
                {
                    filterData?.length > 0 ?
                        <Paper elevation={0} sx={{ border: '0px solid #E1E3EB', borderRadius: '8px', minHeight: '20vh' }}>

                            <DataGrid rows={filterData} columns={column}    pageSizeOptions={[5, 10, 25,50]}  initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              } 
            }}
                                />
                        </Paper>
                        :

                        <>
                            <Grid container>
                                <Grid item md={12} xs={12} lg={12}  >
                                    <Paper elevation={0} sx={{ border: '0px solid #E1E3EB', borderRadius: '8px', minHeight: '20vh' }} className="d-flex justify-content-center align-items-center">

                                        <div className=' p-4'>No data available</div>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </>

                }



            </div>


        </div>
    )
}

export default Page;
