import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import pichainlogo from "../../Assests/pichainlogo.png";
import {useNavigate, useLocation, Link } from 'react-router-dom';
import { Button, Divider, Modal, Typography, useMediaQuery } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import DashboardStore from "../../Helpers/store/mainLayoutStore";
import { useAuthStore, useInformationStore } from "../../Helpers/store/loginStore";
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const drawerWidth = 220;
const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 584,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  padding: '32px',
  border: 'none'

};

export default function SideNavigation() {
  const location = useLocation();
  const pathname = location.pathname;
  const theme = useTheme();
  const router = useNavigate();
  const drawer = React.useContext(ThemeContext);
  const { bears, toggleBears } = DashboardStore();
  const { setAuthenticated } = useAuthStore();
  const { setCustomerProfile } = useInformationStore();
  const { environment } = useInformationStore();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false)

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
    justifyContent: "center",
    height: "80px",
    // paddingLeft: "36px"
  }));
  const DrawerFooter = styled("div")(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "5rem",
    textAlign: "center",
  }));

  const handleSignOut = () => {
    router('/');
    setAuthenticated(false);
    localStorage.clear();
  }

  const handleNavigation = (type: string) => {
    toggleBears();
    router(`/${type}`)
  }

  const hanldeModal = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  }


  const ActiveTab: React.CSSProperties = { color: "#1E5198", fontWeight: 'bold', textDecorationColor: " #EAC687", textDecorationThickness: '2px' };
  const NonActiveTab: React.CSSProperties = { textDecoration: "none", color: "inherit" };
  const MenuItems = <List sx={{ display: "flex", flexDirection: "column" }} className="mx-auto mt-4">
    {/* <ListItem disablePadding className="mt-1"> */}
    <ListItem disablePadding>
      <ListItemButton onClick={toggleBears}>
        <Link className="LinkTag" to="/Dashboard" style={pathname === "/Dashboard" ? ActiveTab : NonActiveTab}>
          <p style={pathname === "/Dashboard" ? { color: '#1E5198', fontWeight: 900 } : { color: 'black' }}  > Dashboard </p>
        </Link>
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding>
      <Link className="LinkTag" to="/Services" style={pathname === "/Services" ? ActiveTab : NonActiveTab}>
        <ListItemButton onClick={toggleBears}>
          <p style={pathname === "/Services" ? { color: '#1E5198', fontWeight: 900 } : { color: 'black' }}  > Services </p>
        </ListItemButton>
      </Link>
    </ListItem>
    <ListItem disablePadding>
      {
        environment ? <Link className="LinkTag" to="/Wallet" style={pathname === "/Wallet" ? ActiveTab : NonActiveTab}>
          <ListItemButton onClick={toggleBears}>
            <p style={pathname === "/Wallet" ? { color: '#1E5198', fontWeight: 900 } : { color: 'black' }}  > Wallet </p>
          </ListItemButton>
        </Link> :
          <ListItemButton onClick={() => hanldeModal()}>
            <p style={pathname === "/Wallet" ? { color: '#1E5198', fontWeight: 900 } : { color: 'black' }}  > Wallet </p>
          </ListItemButton>
      }

    </ListItem>
    <ListItem disablePadding>
      <Link className="LinkTag" to="/Reports" style={pathname === "/Reports" ? ActiveTab : NonActiveTab}>
        <ListItemButton onClick={toggleBears}>
          <p style={pathname === "/Reports" ? { color: '#1E5198', fontWeight: 900 } : { color: 'black' }}  > Reports </p>

        </ListItemButton>
      </Link>
    </ListItem>
    <ListItem disablePadding>
      <Link className="LinkTag" to="/Settings" style={pathname === "/Settings" ? ActiveTab : NonActiveTab}>
        <ListItemButton onClick={toggleBears}>
          <p style={pathname === "/Settings" ? { color: '#1E5198', fontWeight: 900 } : { color: 'black' }}  > Settings </p>

        </ListItemButton>
      </Link>
    </ListItem>
 

  </List>
  return (
    <Box>
      {
        !isMobileScreen ?
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                border: "1px solid #E1E3EB",
                backgroundColor: "FEFEFE",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <DrawerHeader>
              <img src={pichainlogo} alt="Description" width={127} height={38} />
            </DrawerHeader>
            {MenuItems}
            <Box>
              <DrawerFooter><Typography onClick={(e: any) => handleSignOut()} className="fw-bold" sx={{ cursor: 'pointer' }}>Sign out</Typography></DrawerFooter>
            </Box>
          </Drawer> :
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                border: "1px solid #E1E3EB",
                backgroundColor: "FEFEFE",
              },
            }}
            open={bears} onClose={toggleBears}>
            <DrawerHeader>
              <img src={pichainlogo} alt="Description" width={127} height={38} />
            </DrawerHeader>
            {MenuItems}
            <Box >
              <DrawerFooter ><Typography onClick={(e: any) => handleSignOut()} className="fw-bold" sx={{ cursor: 'pointer' }} >Sign out</Typography></DrawerFooter>
            </Box>
          </Drawer>
      }

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography >The wallet is not enabled for test mode. If you want to access it, please switch to live mode.</Typography>
          <div className='d-flex justify-content-center gap-3 mt-4'>
            <Button variant='contained' className='EnableButton' onClick={handleClose}>Close</Button>
          </div>
        </Box>
      </Modal>

    </Box>
  );
}
