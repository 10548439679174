import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
// import "../global.css";
import { ChangeEvent, MouseEvent, ReactNode, useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Modal, TextField, Typography, useMediaQuery } from "@mui/material";
import loginImage from "../../Assests/loginImage.png";
// import Image from "next/image";
import pichainlogo from "../../Assests/pichainlogo.png";
import InputField from "../../component/InputField";
import { getCustomer, signUp, userLogin } from "../../Helpers/Apiservice";
import Loader from "../../component/Loader";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import SnackPopup from '../../component/SnackPopup';
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button1 from "../../component/Button/index";
import { useNavigate } from 'react-router';
import { styled, useTheme } from "@mui/material/styles";


interface State {
    username: string
    email: string
    password: string
    orgname: string
    contact: string
    confirmPassword: string
}

type SIGNUP_RQST_BODY = {
    username: string
    email: string
    password: string
    showPassword?: boolean
    org_name: string
    contact: string
}

const Signup = () => {
    const router = useNavigate();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [errorEmail, setErrorEmail] = useState<string>("")
    const [errorPassword, setErrorPassword] = useState<string>("")
    const [values, setValues] = useState<State>({
        username: '',
        email: '',
        password: '',
        orgname: '',
        contact: '',
        confirmPassword: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarError, setSnackbarError] = useState<"error" | "success" | "warning">("success");
    const [submit, setSubmit] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword1, setShowPassword1] = useState(false);
    const [confrimError, setConfrimError] = useState("");
    const [confrimStatus, setConfrimStatus] = useState(true);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));


    const submitEnable = () => {
        if (values.username != "" && values.email != "" && values.orgname != "" && values.contact != "" && values.password != "" && !confrimStatus && errorEmail == "" && errorPassword == "") {
            return false;
        } else {
            return true
        }
    }

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };




    const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
        if (prop === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(event.target.value)) {
                setValues({ ...values, [prop]: event.target.value });
                setErrorEmail("Invalid Email")
                setSubmit(false);
            } else {
                setErrorEmail("")
                setSubmit(true);
                setValues({ ...values, [prop]: event.target.value });

            }
        } else if (prop === 'password') {
            const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
            if (!passwordRegex.test(event.target.value)) {
                setErrorPassword("Password needs 8+ characters with digits, special characters, letters.");
                setSubmit(false);
                setValues({ ...values, [prop]: event.target.value });
            } else {
                setErrorPassword("");
                setSubmit(true);
                setValues({ ...values, [prop]: event.target.value });
            }
            if (event.target.value == values.confirmPassword) {
                setConfrimError("");
                setConfrimStatus(false);
            } else {
                setConfrimError("Passwords do not match. Please try again.");
                setConfrimStatus(true);
            }
        }
        setValues({ ...values, [prop]: event.target.value });

    }

    const handleChange1 = (event: React.ChangeEvent<{ value: string }>) => {
        setValues({ ...values, "confirmPassword": event.target.value });
        let value = event.target.value;
        if (value == values.password) {
            setConfrimError("");
            setConfrimStatus(false);
        } else {
            setConfrimError("Passwords do not match. Please try again.");
            setConfrimStatus(true);
        }
    }
    const handlePhoneInputChange = (phoneNumber: string) => {
        setValues(prevState => ({
            ...prevState,
            contact: phoneNumber
        }));

    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1)
    }

    const handleMouseDownPassword1 = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleSubmit = () => {
        const payload: SIGNUP_RQST_BODY = {
            username: values?.username,
            email: values?.email,
            password: values?.password,
            org_name: values?.orgname,
            contact: values?.contact
        };
        signUp(payload)
            .then((res) => {
                if (res?.data?.code === 200) {
                    setLoading(false);
                    handleSnackbarOpen();
                    setSnackbarMessage(res?.data?.status?.statusMessage);
                    setSnackbarError("success");
                    setTimeout(() => {
                        router('/');
                    }, 2000);

                } else {
                    setLoading(false);
                    handleSnackbarOpen();
                    setSnackbarMessage("user name is already taken");
                    setSnackbarError("warning");
                }
            })
            .catch((error) => {
                setLoading(false);
                handleSnackbarOpen()
                setSnackbarError("error");
                setSnackbarMessage(error?.response?.data?.status?.statusMessage);
            })
    }

  return (
    <div>
            <Loader loading={loading} />
            <SnackPopup message={snackbarMessage}
                color={snackbarError}
                open={snackbarOpen}
                handleClose={handleSnackbarClose} />
            <Grid container spacing={2}>
                {
                    isMobileScreen ? null : 
                    <Grid item md={6} lg={6} xs={0} className=" bg-light w-100 ">
                    <div className="d-flex flex-column justify-content-end">
                        <img src={loginImage} className="ImageLogin" alt="Big Image" style={{ alignSelf: "flex-end" }} />
                        <div className="containerContent">The best background check solution for businesses of all sizes</div>
                    </div>
                </Grid>
                }
               
                <Grid item md={6} lg={6} xs={12} className=" GridRight">
                    <Box className="d-flex justify-content-center p-4">
                        <div>
                            <div className="d-flex justify-content-center">
                                <img src={pichainlogo} alt="Description" width={127} height={38} />
                            </div>
                            <Box sx={{ marginTop: "24px" }} className="containerLogin">
                                <Typography className="fw-bold" sx={{ fontSize: "24px" }}>
                                    Sign up
                                </Typography>
                                <label className="LabelLogin">Full Name<span className="required">*</span></label>
                                <InputField placeholder="Enter Full Name" onChange={handleChange('username')} />
                                <label className="LabelLogin">Phone Number<span className="required">*</span></label>
                                <PhoneInput specialLabel={''}
                                    onChange={handlePhoneInputChange}
                                    country={'in'}
                                    inputStyle={{
                                        borderColor: "#E0E3E7",
                                        width: "100%",
                                        padding: '20px 40px 20px ',
                                        borderRadius: '8px'
                                    }}
                                />

                                <label className="LabelLogin">Email Address<span className="required">*</span></label>
                                <InputField placeholder="Enter Email Address" type="email" onChange={handleChange('email')} error={errorEmail} />
                                <label className="LabelLogin">Organization Name<span className="required">*</span></label>
                                <InputField placeholder="Enter Organization Name" type="text" onChange={handleChange('orgname')} />

                                <label className="LabelLogin">Password<span className="required">*</span></label>
                                <InputField placeholder="Enter Password" type={showPassword ? 'text' : 'password'} onChange={handleChange('password')} error={errorPassword} endAdornment={
                                    <InputAdornment position='end' style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', marginRight: '10px' }}>
                                        <IconButton
                                            edge='end'
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword1}
                                            aria-label='toggle password visibility'
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                <label className="LabelLogin">Confirm Password<span className="required">*</span></label>
                                <InputField error={confrimError}
                                    placeholder="Enter Confirm Password"
                                    type={showPassword1 ? 'text' : 'password'}
                                    onChange={handleChange1}
                                    endAdornment={
                                        <InputAdornment position='end' style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', marginRight: '10px' }}>
                                            <IconButton
                                                edge='end'
                                                onClick={handleClickShowPassword1}
                                                onMouseDown={handleMouseDownPassword1}
                                                aria-label='toggle password visibility'
                                            >
                                                {showPassword1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <Button1 className="w-100 mt-4 " variant="contained" onClick={handleSubmit} disabled={submitEnable()}>
                                    Sign Up
                                </Button1>
                            </Box>
                        </div>
                    </Box>
                </Grid>
            </Grid>

        </div>
  )
}

export default Signup
