// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-135d0i0-MuiInputBase-root-MuiOutlinedInput-root {
 background-color: #F7F8FA;
 height: 32px;
 border: 'none !important';
font-size: '12px !important'
}
.css-i4bv87-MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/component/DatePicker/index.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,YAAY;CACZ,yBAAyB;AAC1B;AACA;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".css-135d0i0-MuiInputBase-root-MuiOutlinedInput-root {\n background-color: #F7F8FA;\n height: 32px;\n border: 'none !important';\nfont-size: '12px !important'\n}\n.css-i4bv87-MuiSvgIcon-root {\n    width: 20px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
