import * as React from "react";
import { useState, useRef } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler, plugins } from "chart.js";
import { Box, Button, Card, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomizedSelects from "../../component/DropDown/index";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./index.css";
import Offline from "../../Assests/Offline.svg";
import Online from "../../Assests/Online.svg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { getServices, totalBreakDown, predicatedDate, apiHitCounts, increasePercentage, serviceUsageData, walletUsageData, walletAllServices, serviceAllServices, getServices1 } from "../../Helpers/Apiservice";
import { useInformationStore } from "../../Helpers/store/loginStore"
import DashColor from "../../component/DashColor"
import Loader from "../../component/Loader";
import { format } from 'date-fns';
import { IApiCount, IBreakCostApi, IBreakCostDown, IPercentageCount, IPredictedDate, IserviceData, IWalletData } from "../../shared/dashboard";
import NorthIcon from '@mui/icons-material/East';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


ChartJS.register(Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler, ArcElement);

const container: React.CSSProperties = {
  boxShadow: "none",
  border: "1px solid #E1E3EB",
  borderRadius: "16px",
};
const lineChart: React.CSSProperties = {
  // borderLeft: "4px solid #F7F8FA", 
  // borderBottom: "4px solid #F7F8FA",
  width: '100%'
};
const rotatedText: React.CSSProperties = {
  position: 'absolute',
  top: "50%",
  left: -45,

}
const rotatedText1: React.CSSProperties = {
  position: 'absolute',
  top: "50%",
  left: -35,

}

function App() {
  function getDateBeforeSixMonths(): string {
    const currentDate = new Date();
    const sixMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());

    return formatDate(sixMonthsAgo);
  }
  function getDateBeforeThreeMonths(): string {
    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate());

    return formatDate(threeMonthsAgo);
  }

  function getDateBeforeOneMonth(): string {
    const currentDate = new Date();
    const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());

    return formatDate(oneMonthAgo);
  }

  function getDateBeforeOneWeek(): string {
    const currentDate = new Date();
    const oneWeekAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);

    return formatDate(oneWeekAgo);
  }
  function getCurrentDate(): string {
    const currentDate = new Date();
    return formatDate(currentDate);
  }
  const oneWeekAgoDate = getDateBeforeOneWeek();

  const oneMonthAgoDate = getDateBeforeOneMonth();

  const threeMonthsAgoDate = getDateBeforeThreeMonths();

  const sixMonthsAgoDate = getDateBeforeSixMonths();

  const TodayDate = getCurrentDate();




  const theme = useTheme();
  const [service, setService] = useState("All_services");
  const [service1, setService1] = useState("charge");
  const [TableStatus, setTableStatus] = useState<string>("Status");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { environment } = useInformationStore();
  const [recharge, setRecharge] = useState("");
  const [endDate, setEndDate] = useState(sixMonthsAgoDate);
  const [hoverLabel1, setHoverLabel1] = useState("charge");
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceOptions, setServiceOptions] = useState();
  const [serviceSkeleton, setServiceSkeleton] = useState<boolean>(false);
  const [apiHitCount, setApiHitCount] = useState<IApiCount>({ Apihits_Count: null });
  const [prediction, setPredication] = useState<IPercentageCount>({
    Percentage_Change: null
  })
  const [predictionDate, setPredicationDate] = useState<IPredictedDate>({
    Predicted_Date: "DD-MM-YYYY"
  }
  );
  const [activeServices, setActiveService] = useState<number | null>(null);
  const [totalDown, setTotalDown] = useState<IBreakCostDown[]>([{
    data: 0,
    serviceName: '',
    percentage: 0
  }])
  const [serviceData, setServiceData] = useState<IserviceData[]>([{
    count: 0,
    date: ''
  }]);

  const [walletData, setWalletData] = useState<IWalletData[]>([{
    chargedAmount: 0,
    date: ''
  }]);


  React.useEffect(() => {
    getBreakdownData("charge", endDate);
    getService();
    predicatedPercentage(endDate);
    serviceUsage(endDate, "All_services");
    walletUsage(endDate, "All_services")
  }, [environment]);





  const serviceUsage = (startDate: string, type: string) => {
    setServiceSkeleton(true)

    let payload = {
      orgId: environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId"),
      startDate: startDate,
      endDate: TodayDate,
      service_name: type
    }
    if (type == "All_services") {
      serviceAllServices(payload)
        .then((res: any) => {
          let tempData = res.data;
          tempData.sort((a: any, b: any) => {
            const dateA = new Date(a?.date.split("-").reverse().join("-"));
            const dateB = new Date(b?.date.split("-").reverse().join("-"));
            return dateA?.getTime() - dateB?.getTime();
          })

          
          setServiceData(tempData)
          setServiceSkeleton(false)

        })
        .catch((err: any) => {
          console.log(err);
          setServiceSkeleton(false)

        })
    } else {
      serviceUsageData(payload)
        .then((res: any) => {
          let tempData = res.data;
          setServiceData(tempData)
          setServiceSkeleton(false)
        })
        .catch((err: any) => {
          console.log(err);
          setServiceSkeleton(false)
        })
    }

  }

  const walletUsage = (startDate: string, type: string) => {
    setServiceSkeleton(true)

    let payload = {
      orgId: environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId"),
      startDate: startDate,
      endDate: TodayDate,
      service_name: type
    }
    if (type == "All_services") {
      walletAllServices(payload)
        .then((res: any) => {
          setServiceSkeleton(false)
          let tempData = res.data;
          tempData.sort((a: any, b: any) => {
            const dateA = new Date(a?.date.split("-").reverse().join("-"));
            const dateB = new Date(b?.date.split("-").reverse().join("-"));
            return dateA?.getTime() - dateB?.getTime();
          })
          setWalletData(tempData)
        })
        .catch((err: any) => {
          setServiceSkeleton(false)
          console.log(err);
        })
    } else {
      walletUsageData(payload)
        .then((res: any) => {
          setServiceSkeleton(false)
          let tempData = res.data;
          setWalletData(tempData)
        })
        .catch((err: any) => {
          setServiceSkeleton(false)
          console.log(err);
        })
    }

  }

  const getService = () => {
    let payload = environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId")
    getServices1(payload)
      .then((res: any) => {
        let temp_Array = res.data.data;
        let options = temp_Array?.reduce((acc: { value: string; label: string; }[], item: { type: string }) => {
          acc.push({
            label: item.type,
            value: item.type
          });
          return acc;
        }, [{ label: "All services", value: "All_services" }]);
        setActiveService(options.length - 1)
        setServiceOptions(options.reverse());

      })
      .catch((err: any) => {
        console.log();

      })
  }

  const predicatedPercentage = (startDate: string) => {
    let payload = {
      startDate: startDate,
      endDate: TodayDate,
      orgId: environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId")

    }
    apiHitCounts(payload)
      .then((res: any) => {
        let response = res.data;
        setApiHitCount(response)
      })
      .catch((err: any) => {
      })
    predicatedDate(payload)
      .then((res: any) => {
        let response = res.data;
        setPredication(response)

      })
      .catch((err: any) => {
        console.log(err);

      })
    increasePercentage(payload)
      .then((res: any) => {
        let response = res.data;
        setPredicationDate(response)
      })
      .catch((err: any) => {
        console.log(err);


      })


  }

  const getBreakdownData = (type: string, startDate: string) => {
    setLoading(true);
    setServiceSkeleton(true)

    let payload = {
      startDate: startDate,
      endDate: TodayDate,
      type: type == "charge" ? "charge" : "",
      orgId: environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId")
    }
    totalBreakDown(payload)
      .then((res: any) => {
        setLoading(false)

        let tempArray = res.data;
        let responseData = tempArray.map((item: IBreakCostApi, index: number) => {
          return {
            serviceName: item?._id ? item._id : item.serviceName,
            percentage: item.percentage,
            data: item?.Count ? item.Count : item.ChargedAmount
          }
        })
        setTotalDown(responseData);
        setServiceSkeleton(false)

        let temp = service1 == "charge" ? "charge: ₹" : "count"
        setHoverLabel1(temp)
      })
      .catch((err: any) => {
        setServiceSkeleton(false)

        setLoading(false)
        console.log(err, "udrtyui");
      })
  }

  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }


  const formatDate1 = (dateString: string): string => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    return format(date, 'd MMM yyyy');
  };

  const formatDate2 = (dateString: string): string | JSX.Element => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={"50%"} />;
    }
    return format(date, 'd MMM yyyy');
  };

  const options1 = [
    { value: "charge", label: "By Cost" },
    { value: "s", label: "By Api Count" },
  ];

  const rechargeOptions = [
    { value: "7", label: "Last 7 days" },
    { value: "30", label: "Last 30 days" },
    { value: "3m", label: "Last 3 months" },
    { value: "6m", label: "Last 6 months" },
  ];

  const containerRef = useRef(null);


  const colorPie = [
    "#2E7D78", "#1E5198", "#878BEA", "#8CB9BD", "#824D74", "#8C6A5D",
    "#F36D6F", "#3F88C5", "#FFC857", "#4EA1D3", "#F9844A", "#5ABB56",
    "#CC9900", "#FF6666", "#3399FF", "#FF9933", "#663399", "#99CC00",
    "#9933FF", "#66CCCC", "#999966", "#FF9999", "#66CC99", "#FFCC00",
    "#FF6699", "#0099CC", "#FF6600", "#CCCC66", "#3366FF", "#669966",
    "#FF99CC"
  ];



  const doughnutLabel = {
    id: "doughnut",
    beforeDatasetsDraw(chart: any, args: any, pluginOptions: any) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.font = "bold 30px sans-serif";
      ctx.fillStyle = "rgba(54,162,235,1)";
      ctx.textAlign = "center"
      ctx.fillText(`${data.datasets[0].data[0]}`, xCoor, yCoor)

    }
  }
  const hoverLabel = {
    id: 'hoverLabel',
    afterDraw(chart: any, args: any, options: any) {
      if (chart.config.type === 'doughnut') {
        // Your customization for doughnut chart hover labels
        const { ctx, chartArea: { left, right, top, bottom, width, height } } = chart;
        ctx.save();

        if (chart._active.length > 0) {
          const textLabel = chart.config.data.labels[chart._active[0].index];
          const numberLabel = chart.config.data.datasets[chart._active[0].datasetIndex].data[chart._active[0].index];
          const color = chart.config.data.datasets[chart._active[0].datasetIndex].backgroundColor[chart._active[0].index];
          ctx.font = "bold 15px sans-serif";
          ctx.fillStyle = color;
          ctx.textAlign = "center";
          const labelPrefix = "Usage: ";
          // debugger


          ctx.fillText(`${labelPrefix}${numberLabel} %`, width / 2, height / 2 + top);
        }
      }

    }
  }
  Chart.register(hoverLabel);
  const pieData = {
    labels: totalDown.map((item, index) => item.serviceName),
    datasets: [
      {
        data: totalDown.map((item, index) => item.percentage.toFixed(1)),
        backgroundColor: colorPie,
        borderWidth: 5,
        hoverOffset: 10
      },
    ],

    plugins: [doughnutLabel],
  };
  const serviceGraph = {
    labels: serviceData?.map((item, index) => item.date),
    datasets: [
      {
        borderWidth: 1,
        data: serviceData.map((item: IserviceData, index: number) => item.count),
        backgroundColor: "#E1EBFF",
        borderColor: "#1E5198",
        tension: 0.4,
        fill: true,
        // pointStyle: 'rect',
        pointBorderColor: "blue",
        pointBackgroundColor: "#fff",
        showLine: true,
        drawBorder: true,
      },
    ],
  }


  const walletGraph = {
    labels: walletData.map((item, index) => item.date),
    datasets: [
      {
        borderWidth: 1,
        data: walletData.map((item, index) => item.chargedAmount),
        backgroundColor: "#f6ecd9",
        borderColor: "orange",
        tension: 0.4,
        fill: true,
        // pointStyle: 'rect',
        pointBorderColor: "orange",
        pointBackgroundColor: "#fff",
        showLine: true,
        drawBorder: true,
      },
    ],
  }



  // const serviceGraphOptions = 
  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    setService(event.target.value as string);
    serviceUsage(endDate, event.target.value)
    walletUsage(endDate, event.target.value)
  };
  const handleChange1 = (event: React.ChangeEvent<{ value: string }>) => {
    let type: string = event.target.value
    console.log(type, "kjhgfdrtgyui");

    setService1(type);
    getBreakdownData(type, endDate)
  };

  const dashboardData = (data: string) => {
    setEndDate(data)
    getBreakdownData(service1, data)
    predicatedPercentage(data)
    serviceUsage(data, service);
    walletUsage(data, service)
  }
  const handleRecharge = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRecharge(event.target.value as string);
    let rechargeData = event.target.value;
    if (rechargeData == "7") {
      dashboardData(oneWeekAgoDate);

    } else if (rechargeData == "30") {
      dashboardData(oneMonthAgoDate);

    } else if (rechargeData == "3m") {
      dashboardData(threeMonthsAgoDate);

    } else if (rechargeData == "6m") {
      dashboardData(sixMonthsAgoDate);

    }
  };


  // const serviceData = [42, 51, 82, 31, 59, 61, 73, 91, 58, 61, 73, 91, 58];
  const WalletUsage = [58, 59, 61, 73, 91, 58, 42, 51, 82, 31, 61, 73, 91];

  const [usage, setUsage] = useState<string>("Service");
  const [data, setData] = useState({
    data: {
      
      labels: serviceData.map((item: IserviceData, index: number) => formatDate1(item.date)),

      datasets: [
        {
          borderWidth: 1,
          data: serviceData.map((item: IserviceData, index: number) => item.count),

          backgroundColor: "#E1EBFF",
          borderColor: "#1E5198",
          tension: 0.4,
          fill: true,
          // pointStyle: 'rect',
          pointBorderColor: "blue",
          pointBackgroundColor: "#fff",
          showLine: true,
          drawBorder: true,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: 'white', // Change tooltip background color to black
          titleColor: 'black', // Change tooltip title color to white
          bodyColor: 'black', // Change tooltip body color to white
          borderColor: '#E1E3EB', // Change tooltip border color to black
          borderWidth: 2, // Set border width
          callbacks: {
            label: function (context: any) {
              var label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += 'Count: ' + context.parsed.y;
              }
              return label;
            },
          }
        },
      },
      scales: {
        y: {
          display: true,
          grid: {
            display: false,
          },
        },
        x: {
          display: false,
          grid: {
            display: false,
          },
        },
      },
    },
  });
  const [data1, setData1] = useState({
    data: {
      labels: ["Jan", "Feb", "March", "April", "May", "June", "July", "August", "September", "Oct", "Nov", "Dec"],
      datasets: [
        {
          borderWidth: 1,
          data: WalletUsage,
          backgroundColor: "#f6ecd9",
          borderColor: "orange",
          tension: 0.4,
          fill: true,
          // pointStyle: 'rect',
          pointBorderColor: "orange",
          pointBackgroundColor: "#fff",
          showLine: true,
          drawBorder: true,
        },
      ],
    },
    options: {
      plugins: {

        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: 'white', // Change tooltip background color to black
          titleColor: 'black', // Change tooltip title color to white
          bodyColor: 'black', // Change tooltip body color to white
          borderColor: '#E1E3EB', // Change tooltip border color to black
          borderWidth: 2, // Set border width
          callbacks: {
            label: function (context: any) {
              var label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += 'charged Amount : ₹' + context.parsed.y;
              }
              return label;
            },
          }
        },
      },
      scales: {
        y: {
          display: true,
          grid: {
            display: false,
          },
        },
        x: {
          display: false,
          grid: {
            display: false,
          },
        },
      },
    },
  });
  const columnsTab1: GridColDef[] = [
    { field: "name", headerName: "Api Name", width: 250 },
    {
      field: "status",
      headerName: "Api Cost",
      width: 250,
      renderCell: (params) => <div>₹{params.value}</div>,
    },
    {
      field: "firstName",
      headerName: "Total Requests",
      width: 250,
    },
    {
      field: "Successful",
      headerName: "Successful Requests",
      width: 200,
      renderCell: (params) => (
        <div className="d-flex gap-3 align-items-center">
          <div style={{ color: "#2E7D32", fontWeight: 800 }}>{params.value}</div>
          <div className="amountBox">98.2%</div>
        </div>
      ),
    },
    {
      field: "Failure",
      headerName: "Failure Requests",
      width: 200,
      renderCell: (params) => (
        <div className="d-flex gap-3 align-items-center">
          <div style={{ color: "#9A1E1E", fontWeight: 800 }}>{params.value}</div>
          <div className="amountBox">8.2%</div>
        </div>
      ),
    },
  ];

  const rowsTab1 = [
    { id: 1, name: "Aadhar Verification", status: "20", firstName: "1292", Successful: 1270, Failure: 22 },
    { id: 2, name: "Aadhar Verification", status: "2.12", firstName: "129", Successful: 1270, Failure: 22 },
    { id: 3, name: "Aadhar Verification", status: "789", firstName: "800", Successful: 1270, Failure: 22 },
  ];

  const columns: GridColDef[] = [
    { field: "name", headerName: "Api Name", width: 300 },
    {
      field: "firstName",
      headerName: "Pichian Server",
      width: 300,
      renderCell: (params) => (
        <div className="d-flex ">
          {" "}
          {params.value == "Online" ? <img src={Online} alt="Online" /> : <img src={Offline} alt="Offline" />}
          <div style={{ marginLeft: "8px", marginTop: "-1px" }}>{params.value}</div>
        </div>
      ),
    },
  ];

  const rows = [
    { id: 1, name: "Aadhar Verification", status: "Online", firstName: "Online" },
    { id: 2, name: "PAN Verification", status: "Online", firstName: "Online" },
    { id: 3, name: "GST Advance Details", status: "Offline", firstName: "Offline" },
    { id: 4, name: "TAN Verification", status: "Online", firstName: "Online" },
  ];
  return (
    <>
      <Loader loading={loading} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sm={12}>
          {
            serviceSkeleton ? <Card sx={container} className='p-3'>
              <Stack spacing={1}>
                <Skeleton variant="rectangular" height={30} />
                <br />
                <Skeleton variant="rectangular" height={270} />
                <div className="d-flex justify-content-center">
                  <Skeleton variant="rectangular" width={100} height={20} />
                </div>

              </Stack>
            </Card> :

              <Card sx={container} className="p-3">
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={3} className="mt-1">
                    <Typography className={usage === "Service" ? "panelYes" : "panelNo"} onClick={() => setUsage("Service")}>
                      Service Usage
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} className="mt-1 ">
                    <Typography className={usage === "Wallet" ? "panelYes" : "panelNo"} onClick={() => setUsage("Wallet")}>
                      Wallet Usage
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <CustomizedSelects value={service} onChange={handleChange} options={serviceOptions} placeholder="By Services" sx={{ width: "140px" }} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <CustomizedSelects value={recharge} onChange={handleRecharge} options={rechargeOptions} optionTopic="Since Last Recharge" placeholder="Since last recharge" sx={{ width: '130px' }} />
                  </Grid>
                </Grid>
                <div className="pt-4" style={{ minHeight: isMobileScreen ? "100%" : "320px", maxHeight :"500px", position: "relative" }}>
                  {usage == "Service" ? (
                    <>
                      {
                        serviceData[0]?.date !== '' && serviceData.length > 0 ?
                          <div >
                            <div style={rotatedText}>
                              <div style={{ transform: 'rotate(270deg)', color: '#1e5198' }}>Api Hits<NorthIcon /></div>
                            </div>
                            <Line data={serviceGraph} options={data.options} style={lineChart}>
                              Hello
                            </Line>
                            <div >
                              <div style={{ position: 'absolute', bottom: -20, left: "45%", color: '#1e5198' }} > Time<NorthIcon /> </div>
                            </div>

                          </div> : <div style={{ position: 'absolute', left: "40%", top: "40%" }}>No data available</div>
                      }

                    </>

                  ) : (
                    <div >
                      {
                        walletData[0]?.date !== '' && walletData.length > 0 ?
                          <div style={{ position: 'relative' }}>
                            <div style={rotatedText1}>
                              <div style={{ transform: 'rotate(270deg)', color: '#1e5198' }}>Cost <NorthIcon /></div>
                            </div>
                            <Line data={walletGraph} options={data1.options} style={lineChart}>
                              Hello
                            </Line>
                            <div >
                              <div style={{ position: 'absolute', bottom: -20, left: "45%", color: '#1e5198' }} > Time<NorthIcon /> </div>
                            </div>

                          </div>
                          : <div style={{ position: 'absolute', left: "40%", top: "40%" }}>No data available</div>
                      }

                    </div>
                  )}
                </div>
              </Card>
          }

        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          {
            serviceSkeleton ?
              <Card sx={container} className='p-3'>
                <Stack spacing={1}>
                  <div className="d-flex gap-2 justify-content-between">
                    <Skeleton variant="rectangular" width={150} height={30} />
                    <Skeleton variant="rectangular" width={300} height={30} />
                  </div>
                  <Grid container spacing={2} className="mt-3">
                    <Grid item xs={6}>
                      <Skeleton variant="rounded" height={"295px"} />
                    </Grid>
                    <Grid item xs={6}>
                      <Card elevation={0}>
                        <div className="p-3">
                          <Stack spacing={1}>
                            <div className="d-flex gap-2 align-items-center">
                              <Skeleton variant="circular" width={20} height={20} />
                              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={"50%"} />
                            </div>
                            <div className="d-flex gap-2 justify-content-between">
                              <Skeleton variant="rectangular" width={60} height={30} />
                              <Skeleton variant="rectangular" width={60} height={30} />
                            </div>
                            <div className="d-flex gap-2 align-items-center mt-2">
                              <Skeleton variant="circular" width={20} height={20} />
                              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={"50%"} />
                            </div>
                            <div className="d-flex gap-2 justify-content-between">
                              <Skeleton variant="rectangular" width={60} height={30} />
                              <Skeleton variant="rectangular" width={60} height={30} />
                            </div>
                            <div className="d-flex gap-2 align-items-center mt-2">
                              <Skeleton variant="circular" width={20} height={20} />
                              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={"50%"} />
                            </div>
                            <div className="d-flex gap-2 justify-content-between">
                              <Skeleton variant="rectangular" width={60} height={30} />
                              <Skeleton variant="rectangular" width={60} height={30} />
                            </div>
                          </Stack>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>


                </Stack>
              </Card>
              :
              <Card sx={container} className="p-3">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography className="panelNo fw-bold mt-2">Total Breakdown</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} >
                    <Grid container>
                      <Grid item xs={6}>
                        <CustomizedSelects value={service1} onChange={handleChange1} options={options1} placeholder="By Cost" sx={{ width: '140px' }} />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomizedSelects value={recharge} onChange={handleRecharge} options={rechargeOptions} optionTopic="Since Last Recharge" placeholder="Since last recharge" sx={{ width: '130px' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div>
                  {
                    totalDown.length > 0 ?
                      <Grid container spacing={2}>
                        <Grid item md={7} sm={6} xs={12} display={"flex"} justifyContent={"center"}>
                          <div className="pt-4" style={{ height: isMobileScreen ? "200px" : "320px" }}>

                            <Doughnut
                              data={pieData}
                              options={{
                                plugins: {
                                  tooltip: {
                                    callbacks: {
                                      label: function (context) {
                                        return ''; // Empty string to hide the tooltip
                                      }
                                    }
                                  },
                                  legend: {
                                    display: false
                                  }
                                },
                                elements: {
                                  arc: {
                                    borderWidth: 10,
                                    borderColor: '#ffffff', // Example color
                                    borderRadius: 2,
                                  }
                                },
                                scales: {
                                  y: {
                                    display: false,
                                    grid: {
                                      display: false
                                    }
                                  },
                                  x: {
                                    display: false,
                                    grid: {
                                      display: false
                                    }
                                  }
                                }
                              }}
                            />


                            {/* <DognutChart/> */}
                          </div>
                        </Grid>
                        <Grid item md={5} sm={6} xs={12}>
                          <div className="pt-4">
                            <div className="BreakdownDetails " ref={containerRef}>
                              {totalDown.map((item, index) => {
                                return <div className="mb-3">

                                  <div className="d-flex gap-2 mt-1 align-items-center">
                                    <DashColor data={colorPie[index]} />
                                    <Typography sx={{ fontSize: "14px" }}>{item.serviceName}</Typography>
                                  </div>
                                  {
                                    service1 !== "charge" ?
                                      <div className="d-flex gap-2 justify-content-between align-items-center mt-2">
                                        <div className="d-flex align-items-center gap-2">
                                          <Typography sx={{ fontSize: '15px' }}>count :</Typography>
                                          <Typography className="amountBox fw-bold mt-2"> {item.data}</Typography>
                                        </div>
                                        <div>
                                          <Typography className="amountBox fw-bold  mt-2"> {item.percentage.toFixed(0)}%</Typography>
                                        </div>
                                      </div>
                                      :
                                      <div className="d-flex gap-2 justify-content-between align-items-center mt-2">
                                        <Typography className="amountBox fw-bold  mt-2"> ₹{item?.data?.toFixed(0)}</Typography>
                                        <Typography className="amountBox fw-bold  mt-2"> {item?.percentage?.toFixed(0)}%</Typography>

                                      </div>
                                  }
                                  <Divider className="pt-2 " style={{ height: "4px" }} />

                                </div>
                              })}
                            </div>
                            <div className="d-flex justify-content-end gap-3 mt-1">
                              <KeyboardArrowLeftIcon
                              // onClick={scrollLeft}
                              />
                              <KeyboardArrowRightIcon
                              // onClick={scrollRight} 
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      :
                      <div className="pt-4" style={{ height: isMobileScreen ? "200px" : "320px", position: "relative" }}>
                        <div style={{ position: 'absolute', top: '40%', right: '40%' }}>No data available</div>
                      </div>
                  }
                </div>

              </Card>
          }

        </Grid >
      </Grid >
      <Box className="pt-4">
        <Card sx={container} className="p-4">
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography className="fw-bold pb-4">Insights</Typography>
            </Grid>
            <Grid item>
              <CustomizedSelects value={recharge} onChange={handleRecharge} options={rechargeOptions} optionTopic="Since Last Recharge" placeholder="Since last recharge" sx={{ width: "150px" }} />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={3} xs={12} sm={6}>
              <Box className="InsightCard">
                {
                  predictionDate?.Predicted_Date ?
                    <Typography className="InsightHead">{predictionDate?.Predicted_Date ? formatDate2(predictionDate?.Predicted_Date) : "DD-MM-YYYY"}</Typography> :
                    "DD-MM-YYYY"
                }

                <Typography>predicted date on which the wallet balance will run out based on current usage</Typography>
              </Box>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <Box className="InsightCard">
                <Typography className="InsightHead" sx={{ color: "#2E7D32" }}>
                  {
                    prediction?.Percentage_Change !== null ? `${prediction?.Percentage_Change?.toFixed(1)}%` : <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={"50%"} />
                  }

                </Typography>
                <Typography>increase in your usage of all Services compared to the previous period.</Typography>
              </Box>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <Box className="InsightCard">
                <Typography className="InsightHead">{apiHitCount?.Apihits_Count !== null ? apiHitCount?.Apihits_Count : <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={"50%"} />}</Typography>
                <Typography>times your APIs were used</Typography>
              </Box>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <Box className="InsightCard">
                <Typography className="InsightHead">{activeServices !== null ? activeServices : <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={"50%"} />}</Typography>
                <Typography>APIs Enabled</Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
}

export default App;
